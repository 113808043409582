<template>
  <v-dialog :value="value" fullscreen persistent no-click-animation hide-overlay transition="dialog-bottom-transition">
    <Form_Serf :key="item.veevaId" @input="v => $emit('input', v)" :item="item" />
  </v-dialog>
</template>

<script>
import Form_Serf from '@/views/serf/Form'

export default {
  name: 'DialogForm',

  components: {
    Form_Serf
  },

  props: {
    value: Boolean,
    item: Object
  }
}
</script>
