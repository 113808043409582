import gql from 'graphql-tag'

import { HCP_LIST } from '../Hcp/Hcp'

export const VEEVA_USER_FULL = gql`
  fragment VeevaUserFull on VeevaUser {
    veevaId
    firstName
    lastName
    email
    veevaId
    postalCodes
    cities
    rppsNumber
    zone {
      label
    }
    healthCareProfessional {
      ...HealthCareProfessional
    }
  }
  ${HCP_LIST}
`
