<template>
  <v-card v-if="msl" outlined elevation="1" class="MslStats">
    <v-row class="px-6">
      <v-col class="pb-2">
        <span class="primary--text settings-title">{{ $t`my-dashboard` }}</span>
        <v-alert class="mt-2 mb-0" dense type="info">{{ $t('mslStats.infoMessage') }}</v-alert>
      </v-col>
    </v-row>
    <v-card-title class="text-capitalize">{{ msl.firstName }} {{ msl.lastName }}</v-card-title>
    <v-card-subtitle>MSL - {{ msl.zone }}</v-card-subtitle>
    <v-container class="px-6">
      <span class="display-2 font-weight-light mr-5">{{ ratioSigned }}%</span>
      <span class="grey--text">{{ $t`of-my-msl-target` }}</span>
      <v-row align="center">
        <v-col> <v-progress-linear :value="ratioSigned" height="8" class="my-2" /> </v-col>
        <v-col> {{ msl.totalSignedSerf }}/{{ msl.totalTarget }}</v-col>
      </v-row>
    </v-container>
    <v-container fluid class="pa-0 grey--text text--darken-2 title font-weight-regular">
      <v-list class="py-0">
        <v-list-item-group>
          <v-list-item @click="goTo({ lastName: msl.lastSignedLastname, serfStatuses: 'signed' })">
            <v-row class="px-6">
              <v-col cols="8">{{ $t`last-signed-serf` }}</v-col>
              <v-col class="primary--text ellipsis">{{ msl.lastSignedFirstname }} {{ msl.lastSignedLastname }}</v-col>
            </v-row>
          </v-list-item>
          <v-divider />
          <v-list-item @click="goTo({ serfStatuses: 'expired' })">
            <v-row class="px-6">
              <v-col cols="8">{{ $t`serf-out-of-date` }}</v-col>
              <v-col class="purple--text ellipsis">{{ msl.expiredSerf }}</v-col>
            </v-row>
          </v-list-item>
          <v-divider />
          <v-list-item @click="goTo({ serfStatuses: 'expires_soon' })">
            <v-row class="px-6">
              <v-col cols="8">{{ $t`serf-out-of-date-in-2-month` }}</v-col>
              <v-col class="orange--text ellipsis">{{ msl.expireSoonSerf }}</v-col>
            </v-row>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-container>
  </v-card>
  <v-card v-else height="200" width="100%" outlined elevation="1">
    <v-overlay :value="true" opacity="0.03" absolute>
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
  </v-card>
</template>

<script>
import _get from 'lodash/get'

import { GET_USER_STATS } from '@/graphql/User/GetUserStats'

export default {
  name: 'MslStats',

  apollo: {
    msl: {
      query: GET_USER_STATS,
      variables() {
        return { userUuid: this.$store.state.myUser.userUuid }
      },
      update: data => ({
        firstName: _get(data.user, 'firstName'),
        lastName: _get(data.user, 'lastName'),
        userUuid: _get(data.user, 'userUuid'),
        zoneUuid: _get(data.user, 'mslMeta.zone.zoneUuid'),
        zone: _get(data.user, 'mslMeta.zone.label'),
        expireSoonSerf: _get(data.user, 'mslMeta.zone.serfStats.expireSoonSerf') || 0,
        expiredSerf: _get(data.user, 'mslMeta.zone.serfStats.expiredSerf') || 0,
        lastSignedLastname: _get(data.user, 'mslMeta.zone.serfStats.lastSigned.lastName'),
        lastSignedFirstname: _get(data.user, 'mslMeta.zone.serfStats.lastSigned.firstName'),
        totalSignedSerf: _get(data.user, 'mslMeta.zone.serfStats.totalSignedSerf') || 0,
        totalTarget: _get(data.user, 'mslMeta.zone.serfStats.totalTarget') || 0
      })
    }
  },

  computed: {
    ratioSigned() {
      return Math.round((this.msl.totalSignedSerf / this.msl.totalTarget) * 100)
    }
  },

  methods: {
    goTo(query, mslTarget = true, mslResponsibleUuid = this.msl.userUuid) {
      this.$router.push({
        path: `/${this.$route.path.split('/')[1]}/list`,
        query: { ...query, mslTarget, mslResponsibleUuid }
      })
    }
  }
}
</script>
