<template>
  <div class="HcpList">
    <div class="px-8 py-0">
      <v-row dense>
        <v-col cols="6">
          <v-btn class="my-4" color="info" depressed exact :to="{ path: `/${$route.path.split('/')[1]}` }">
            <v-icon left>mdi-chevron-left</v-icon>
            {{ $t('serfApp.backButton') }}
          </v-btn>
        </v-col>

        <v-col cols="6" class="text-right">
          <v-btn
            @click="downloadExport"
            class="my-4 text-none"
            color="success"
            depressed
            exact
            :disabled="total === 0 || total > 1000 || veevaNetwork"
            :loading="loadingDownloadExport"
          >
            {{ $t('hcp-list.export-button') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-container fluid class="pa-0">
      <v-data-table
        :loading="loading"
        :loading-text="$t('charging-hcps')"
        :headers="headers"
        :items="flatVeevaUsers"
        :items-per-page="10"
        :server-items-length="total"
        :footer-props="{
          'items-per-page-options': [10, 20, 50]
        }"
        expand-icon="mdi-view-dashboard"
        :must-sort="mslTarget"
        :disable-sort="!mslTarget"
        :options.sync="dataOptions"
        @click:row="clickRow"
        class="data-table"
      >
        <template v-slot:[`item.specialty`]="{ value }">
          <span>{{ $t(`specialtyValues.${value}`) }}</span>
        </template>

        <template v-slot:[`item.serfStatus`]="{ item }">
          <div :class="`${getColorState(item.serfStatus)}--text font-weight-bold`">
            <div v-if="item.serfStatus" class="status-text">{{ $t(`serf-status-${item.serfStatus}`) }}</div>
            <div class="expiration-date">
              <span v-if="item.serfStatus">{{ getDateStatus(item) }}</span>
              <span v-else class="text-uppercase">non signé</span>
            </div>
          </div>
        </template>

        <template v-slot:[`item.serfLink`]="{ item }">
          <v-btn @click.stop="clickRow(item)" icon color="success">
            <v-icon dense>mdi-text-box-plus-outline</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.mailLink`]="{ item }">
          <v-btn @click.stop="clickLink(item)" icon color="primary">
            <v-icon dense>mdi-email-outline</v-icon>
          </v-btn>
        </template>

        <template v-slot:no-data v-if="!loading && veevaUsers.results && veevaUsers.results.length === 0">
          <div class="mt-12"><NoResult withoutSearch /></div>
        </template>
      </v-data-table>
    </v-container>

    <!-- Error -->
    <div v-if="error" class="pa-5">
      <v-alert type="error">{{ $t('server-error') }}</v-alert>
    </div>

    <Carousel v-model="dialogSerf" :item="itemSelected" :getColorState="getColorState" />
    <ModalGenerateLink v-if="dialogGenerateLink" v-model="dialogGenerateLink" :item="itemSelected" />
  </div>
</template>

<script>
import axios from 'axios'
import _sortBy from 'lodash/sortBy'
import _get from 'lodash/get'
import _unionBy from 'lodash/unionBy'
import { mapGetters } from 'vuex'
import moment from 'moment'

import shared from '@/const/shared'
import Carousel from '@/components/serf/Carousel'
import NoResult from '@/components/serf/NoResult'
import ModalGenerateLink from '@/components/serf/ModalGenerateLink'
import { GET_VEEVA_USERS } from '@/graphql/VeevaUsers/GetVeevaUsers'
import { GET_ALL_ZONES } from '@/graphql/Zone/GetZones'
import { GET_ALL_BUS } from '@/graphql/BusinessUnit/GetBus'
import { SERF_STATUS, TARGETS } from '@/const/serf'

export default {
  name: 'HcpList',

  components: {
    ModalGenerateLink,
    Carousel,
    NoResult
  },

  data() {
    const mslResponsibleUuid =
      typeof this.$route.query.mslResponsibleUuid === 'string' ? this.$route.query.mslResponsibleUuid : null

    return {
      bus: [],
      dataOptions: {
        sortBy: ['lastName'],
        sortDesc: [false]
      },
      dialogGenerateLink: false,
      dialogSerf: false,
      error: false,
      itemSelected: {},
      loading: true,
      loadingDownloadExport: false,
      mslResponsibleUuid: this.$route.query.mslResponsibleUuid || null,
      postalCodeOrCity: this.$route.query.postalCode || this.$route.query.city || '',
      veevaNetwork: this.$route.query.veevaNetwork || false,
      query: {
        haveMslResponsible: this.$route.query.haveMslResponsible
          ? typeof this.$route.query.haveMslResponsible === 'string' &&
            this.$route.query.haveMslResponsible.length !== 0
            ? this.$route.query.haveMslResponsible === 'true'
            : this.$route.query.haveMslResponsible
          : null,
        lastName: this.$route.query.lastName,
        businessUnitUuids: this.convertInArray(this.$route.query.businessUnitUuids),
        limit: this.$route.query.mslTarget === true || this.$route.query.mslTarget === 'true' ? 1000000 : 50,
        mslResponsibleUuid,
        offset: 0,
        serfChoices: this.convertInArray(this.$route.query.serfChoices),
        serfStatuses: this.convertInArray(this.$route.query.serfStatuses),
        targets: this.convertInArray(this.$route.query.targets),
        zoneUuids: this.convertInArray(this.$route.query.zoneUuids)
      },
      searchText: this.$route.query.name,
      status: [
        SERF_STATUS.find(status => status.value === this.$const.serfStatus.SIGNED),
        SERF_STATUS.find(status => status.value === this.$const.serfStatus.NOT_SIGNED),
        SERF_STATUS.find(status => status.value === this.$const.serfStatus.EXPIRED),
        SERF_STATUS.find(status => status.value === this.$const.serfStatus.EXPIRES_SOON),
        SERF_STATUS.find(status => status.value === this.$const.serfStatus.REFUSED),
        SERF_STATUS.find(status => status.value === this.$const.serfStatus.REPEALED)
      ],
      targets: TARGETS,
      zones: []
    }
  },

  computed: {
    ...mapGetters(['hasRight']),
    flatVeevaUsers() {
      if (!this.veevaUsers || (this.veevaUsers && !this.veevaUsers.results)) return []

      return this.veevaUsers.results.map(user => ({
        city: _get(user, 'healthCareProfessional.city', user.cities[this.indexPostalCode(user.postalCodes)]),
        countryCode: _get(user, 'healthCareProfessional.countryCode'),
        email: _get(user, 'healthCareProfessional.emailAddress', user.email),
        emailConsent: _get(user, 'healthCareProfessional.emailConsent'),
        expirationDate:
          this.dateFormat(_get(user, 'healthCareProfessional.scientificExchangeRequestForm.expirationDate')) ||
          this.dateFormat(_get(user, 'healthCareProfessional.refusedDate')),
        firstName: _get(user, 'healthCareProfessional.firstName', user.firstName),
        healthCareProfessionalUuid: _get(user, 'healthCareProfessional.healthCareProfessionalUuid'),
        lastName: _get(user, 'healthCareProfessional.lastName', user.lastName),
        postalCode: _get(
          user,
          'healthCareProfessional.postalCode',
          user.postalCodes[this.indexPostalCode(user.postalCodes)]
        ),
        specialty: _get(user, 'healthCareProfessional.specialty'),
        serfStatus: _get(user, 'healthCareProfessional.hasRefused')
          ? 'refused'
          : _get(user, 'healthCareProfessional.scientificExchangeRequestForm.status'),
        statusDate: _get(user, 'healthCareProfessional.hasRefused')
          ? _get(user, 'healthCareProfessional.refusedDate')
          : _get(user, 'healthCareProfessional.scientificExchangeRequestForm.statusDate'),
        title: _get(user, 'healthCareProfessional.title', '- -'),
        veevaId: user.veevaId,
        zone: _get(user, 'healthCareProfessional.zone.label')
      }))
    },
    headers() {
      const headers = [
        { text: this.$t`lastName`, value: 'lastName' },
        { text: this.$t`firstName`, value: 'firstName' },
        { text: this.$t`city`, value: 'city' },
        { text: this.$t`zip-code`, value: 'postalCode' },
        { text: this.$t`title`, value: 'title' },
        { text: this.$t`specialty`, value: 'specialty', sortable: false },
        { text: this.$t`zone`, value: 'zone', sortable: false },
        { text: this.$t`status`, value: 'serfStatus' }
      ]

      if (this.hasRight(this.$const.right.createSerf)) {
        headers.splice(7, 0, { text: '', value: 'serfLink', width: '12px', align: 'center', sortable: false })
      }

      // if (this.hasRight(this.$const.right.getHcpToken)) {
      //   headers.splice(8, 0, { text: '', value: 'mailLink', width: '12px', align: 'center', sortable: false })
      // }

      return headers
    },
    mslTarget() {
      return this.forceMslTarget || !this.veevaNetwork
    },
    forceMslTarget() {
      return (
        this.$route.query.mslTarget === true ||
        this.$route.query.mslTarget === 'true' ||
        !!this.$route.query.mslResponsibleUuid ||
        (this.availableBusinessUnitExternalIds > 1 && this.query.businessUnitUuids.length > 0) ||
        this.query.serfChoices.length > 0 ||
        this.query.serfStatuses.length > 0 ||
        this.query.targets.length > 0 ||
        this.query.zoneUuids.length > 0
      )
    },
    selectableItems() {
      return this.flatVeevaUsers.filter(user => user.healthCareProfessionalUuid)
    },
    selectedSerfTypes() {
      if (!Array.isArray(this.query.businessUnitUuids) || this.query.businessUnitUuids.length === 0) {
        return this.availableSerfTypes
      }
      return this.$store.state.bus
        .filter(bu => this.query.businessUnitUuids.includes(bu.businessUnitUuid))
        .map(bu => shared.businessUnitFormType[bu.externalId])
        .filter(Boolean)
    },
    availableBusinessUnitExternalIds() {
      return this.$route.meta.availableBusinessUnitExternalIds
    },
    availableBusinessUnits() {
      return this.$store.state.bus.filter(bu => this.availableBusinessUnitExternalIds.includes(bu.externalId))
    },
    availableBusinessUnitUuids() {
      return this.availableBusinessUnits.map(bu => bu.businessUnitUuid)
    },
    availableSerfTypes() {
      return this.availableBusinessUnits
        .map(bu => shared.businessUnitFormType[bu.externalId])
        .filter(Boolean)
        .filter((value, index, self) => self.indexOf(value) === index)
    },
    serfChoices() {
      // Get group in term of serf type
      const selectedSerfGroup = this.selectedSerfTypes.map(serfType => shared.serfGroups[serfType])

      // Merge fields in groups
      const groups = {}
      for (let group of selectedSerfGroup) {
        for (let groupKey of Object.keys(group)) {
          if (!groups[groupKey]) {
            groups[groupKey] = { ...group[groupKey] }
          } else {
            groups[groupKey].fields = _unionBy(groups[groupKey].fields, group[groupKey].fields, 'fieldFullName')
          }
        }
      }

      // Create choices list
      let choices = []
      for (let group of Object.values(groups)) {
        const fields = group.fields.filter(field => field.fieldType === shared.fieldType.BOOLEAN)
        if (fields.length === 0) {
          continue
        }
        choices.push({ header: this.$t(group.label) })
        choices.push({ divider: true })
        choices = choices.concat(
          fields.map(field => {
            let labelSuffix = ''
            if (this.availableSerfTypes.length !== 1 && field.serfType !== shared.serfType.DEFAULT) {
              labelSuffix = ` (${field.serfType})`
            }
            return { value: field.fieldFullName, text: this.$t(field.label) + labelSuffix }
          })
        )
      }
      return choices
    },
    total() {
      return this.veevaUsers ? this.veevaUsers.total || 0 : 0
    },
    user() {
      return this.$store.state.myUser
    }
  },

  apollo: {
    veevaUsers: {
      query: GET_VEEVA_USERS,
      variables() {
        this.loading = true
        this.error = false
        const query = this.generateQuery()

        if (this.dataOptions.page) {
          query.offset = (this.dataOptions.page - 1) * this.dataOptions.itemsPerPage
          query.limit = this.dataOptions.itemsPerPage
        }

        // Sorting only if we search on HCP database (if mslTarget is true)
        if (query.mslTarget && this.dataOptions.sortBy.length !== 0) {
          query.sorts = [{ field: this.dataOptions.sortBy[0], order: this.dataOptions.sortDesc[0] ? 'DESC' : 'ASC' }]
        }

        // Search on HCP database
        return query
      },
      debounce: 500,
      deep: true,
      result() {
        this.loading = false
      },
      error() {
        this.loading = false
        this.error = true
      }
    },
    zones: {
      query: GET_ALL_ZONES,
      update: data => _sortBy(data.zones.map(zone => ({ value: zone.zoneUuid, text: zone.label })), ['text'])
    },
    bus: {
      query: GET_ALL_BUS,
      update: data => _sortBy(data.businessUnits.map(bu => ({ value: bu.businessUnitUuid, text: bu.label })), ['text'])
    }
  },

  methods: {
    clickLink(item) {
      this.itemSelected = item
      this.dialogGenerateLink = true
    },
    generateQuery() {
      const query = {
        ...this.query,
        mslTarget: this.mslTarget
      }

      if (this.postalCodeOrCity) {
        if (isNaN(this.postalCodeOrCity)) query.city = this.postalCodeOrCity
        else query.postalCode = this.postalCodeOrCity
      }

      if (this.dataOptions.page) {
        query.offset = (this.dataOptions.page - 1) * this.dataOptions.itemsPerPage
        query.limit = this.dataOptions.itemsPerPage
      }

      // Search on Veeva network
      if (!query.mslTarget) {
        return {
          mslTarget: false,
          offset: query.offset,
          limit: query.limit,
          lastName: query.lastName,
          postalCode: query.postalCode,
          city: query.city
        }
      }

      // Don't filter by business unit if we have only one business unit available
      if (this.availableBusinessUnitUuids.length === 1) {
        query.businessUnitUuids = [this.availableBusinessUnitUuids[0], null]
      }

      // Don't filter by business unit if we search by MSL Responsible,
      if (query.mslResponsibleUuid) {
        query.businessUnitUuids = null
      }

      return query
    },
    async downloadExport() {
      this.loadingDownloadExport = true
      // eslint-disable-next-line no-unused-vars
      const { offset, limit, ...query } = this.generateQuery()

      const response = await axios.post('/serf/exportSearch', query, {
        responseType: 'blob',
        headers: {
          'x-impersonificated-user': this.$store.state.impersonificatedUser
        }
      })

      const url = URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${moment().format('YYMMDD')}-ExportSERF.xlsx`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)

      this.loadingDownloadExport = false
    },
    getDateStatus(item) {
      if (!item.serfStatus) return 'No Date'

      switch (item.serfStatus) {
        case SERF_STATUS[0].value:
        case SERF_STATUS[1].value:
        case SERF_STATUS[5].value:
          return item.expirationDate
        default:
          return moment(item.statusDate).format('DD/MM/YYYY')
      }
    },
    indexPostalCode(arr) {
      if (!this.$route.query.postalCode) {
        return 0
      }

      return arr.findIndex(el => el === this.$route.query.postalCode)
    },
    getColorState(status) {
      switch (status) {
        case 'signed':
          return 'serf-signed'
        case 'expires_soon':
          return 'serf-soon-expire'
        case 'expired':
          return 'serf-expired'
        case undefined:
          return 'serf-not-signed'
        case 'refused':
          return 'serf-refused'
        case 'repealed':
          return 'serf-repealed'
        default:
          console.error(`error - this status don't map with this switch : ${status}`)
      }
    },
    dateFormat(date) {
      if (date === undefined) {
        return null
      }

      const d = new Date(date)
      const day = ('0' + d.getDate()).slice(-2)
      const month = ('0' + (d.getMonth() + 1)).slice(-2)

      return `${day}/${month}/${d.getFullYear()}`
    },
    clickRow(item) {
      this.updateUrl()
      this.itemSelected = item
      this.dialogSerf = true
    },
    convertInArray(element) {
      if (!element) return []
      if (typeof element !== 'object') {
        return [element]
      }
      return element
    },
    updateUrl() {
      const query = {
        ...this.query
      }

      if (this.postalCodeOrCity) {
        if (isNaN(this.postalCodeOrCity)) query.city = this.postalCodeOrCity
        else query.postalCode = this.postalCodeOrCity
      }

      const url = this.$router.resolve({ path: `/${this.$route.path.split('/')[1]}/list`, query }).href
      history.pushState({}, '', url)
    }
  },
  watch: {
    'dataOptions.page'(val, old) {
      if (val !== old) {
        window.scrollTo({ top: 0 })
      }
    }
  }
}
</script>

<style lang="scss">
.HcpList {
  .noWrap .v-select__selections {
    flex-wrap: nowrap;
  }
}
</style>

<style lang="scss">
.HcpList {
  .data-table {
    tbody tr {
      cursor: pointer;
    }
  }

  .expansion-panel {
    background-color: #fafafa !important;

    :before {
      box-shadow: none;
    }
    .v-expansion-panel-header,
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }

  .expiration-date {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
