<template>
  <v-container class="Serf_No-Result">
    <div class="display-1 my-7 text-center">{{ $t('no-result-found') }}</div>
    <v-row>
      <v-col align-self="center" class="px-8">
        <div class="my-8 text-center"><img src="@/assets/no-results.svg" /></div>
        <p class="text-center subtitle-2 grey--text">{{ $t`check-spelling` }}</p>
      </v-col>
      <v-col v-if="!withoutSearch" align-self="center" class="px-8">
        <div class="label-input body-2 grey--text text--darken-1">Nom</div>
        <v-text-field
          v-model="name"
          outlined
          height="50"
          append-icon="mdi-magnify"
          :placeholder="$t('search-pds-placeholder')"
          @keydown.enter="gotoList"
        />
        <div class="label-input body-2 grey--text text--darken-1">{{ $t('serf-search-location') }}</div>
        <v-text-field
          v-model="postalCode"
          type="number"
          outlined
          height="50"
          append-icon="mdi-map-marker"
          :placeholder="$t('search-zip-or-town-placeholder')"
          @keydown.enter="gotoList"
        />
        <v-btn depressed color="primary" height="50" width="100%" :disabled="!name && !postalCode" @click="gotoList">
          {{ $t('search') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'No-Result',

  props: {
    withoutSearch: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      name: '',
      postalCode: ''
    }
  },

  methods: {
    gotoList() {
      const query = {
        name: this.name,
        postalCode: this.postalCode
      }
      this.$router.push({ path: `/${this.$route.path.split('/')[1]}/list`, query })
      this.$router.go()
    }
  }
}
</script>

<style scoped lang="scss">
.Serf_No-Result {
  max-width: 1100px;
  padding: 0 120px;
}
</style>
