<template>
  <v-row dense>
    <v-col v-show="availableBus.length > 1" :cols="availableBus.length === 1 ? 6 : 4" class="py-0 noWrap">
      <v-select
        v-model="value.businessUnitUuids"
        :items="availableBus"
        menu-props="offsetY"
        label="BU"
        background-color="#f8f8f8"
        filled
        dense
        :disabled="disabled || lockBu"
        hide-details
        multiple
        outlined
      />
    </v-col>
    <v-col :cols="availableBus.length === 1 ? 6 : 4" class="py-0 noWrap">
      <v-select
        v-model="value.zoneUuids"
        :disabled="disabled || !value.businessUnitUuids || value.businessUnitUuids.length === 0 || lockBu"
        :items="zones"
        menu-props="offsetY"
        label="Zone"
        background-color="#f8f8f8"
        filled
        dense
        hide-details
        multiple
        outlined
      />
    </v-col>
    <v-col :cols="availableBus.length === 1 ? 6 : 4" class="py-0 noWrap">
      <v-select
        v-model="value.targets"
        :items="targets"
        :disabled="disabled || !value.businessUnitUuids || value.businessUnitUuids.length === 0"
        menu-props="offsetY"
        :label="$t`targeting`"
        background-color="#f8f8f8"
        filled
        dense
        hide-details
        multiple
        outlined
      >
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
import _sortBy from 'lodash/sortBy'

export default {
  name: 'selectBuZoneTarget',

  props: {
    value: Object,
    disabled: {
      type: Boolean,
      default: false
    },
    lockBu: Boolean
  },

  computed: {
    availableBusinessUnitExternalIds () {
      return this.$route.meta.availableBusinessUnitExternalIds
    },
    availableBusinessUnitUuids () {
      return this.$store.state.bus
        .filter(bu => this.availableBusinessUnitExternalIds.includes(bu.externalId))
        .map(bu => bu.businessUnitUuid)
    },
    availableBus() {
      if (this.availableBusinessUnitUuids.length === 1) {
        this.selectBus(this.availableBusinessUnitUuids)
      }
      return _sortBy(this.$store.state.bus.filter(bu => this.availableBusinessUnitUuids.includes(bu.businessUnitUuid)).map(bu => ({ text: bu.label, value: bu.businessUnitUuid })), ['text'])
    },
    targets() {
      if (!this.value.businessUnitUuids || this.value.businessUnitUuids.length === 0) return []

      return Object.keys(this.$const.targetBusinnesUnit)
        .map(name =>
          this.value.businessUnitUuids.includes(this.$store.state.bus.find(el => el.label === name).businessUnitUuid)
            ? this.$const.targetBusinnesUnit[name]
            : []
        )
        .flat()
        .sort()
    },
    zones() {
      if (!this.value.businessUnitUuids || this.value.businessUnitUuids.length === 0) return []

      return _sortBy(
        this.$store.state.bus
          .filter(bu => this.value.businessUnitUuids.includes(bu.businessUnitUuid))
          .map(bu => bu.zones.map(zone => ({ text: zone.label, value: zone.zoneUuid })))
          .flat(),
        ['text']
      )
    }
  },

  methods: {
    selectBus (businessUnitUuids) {
      this.value.businessUnitUuids = businessUnitUuids
    }
  },

  watch: {
    $route(to, from) {
      if (from.name !== to.name) {
        this.value.businessUnitUuids = []
      }
    },
    'value.businessUnitUuids'() {
      if (this.value.zoneUuids && this.value.zoneUuids.length > 0) {
        this.value.zoneUuids = this.value.zoneUuids.filter(uuid => this.zones.some(zone => zone.value === uuid))
      }
      if (this.value.targets && this.value.targets.length > 0) {
        this.value.targets = this.value.targets.filter(value => this.targets.some(target => target === value))
      }
    }
  }
}
</script>
