import gql from 'graphql-tag'

import { FULL_SERF } from './Serf'

export const GET_SERF = gql`
  query getSerf($serfUuid: GUID!) {
    scientificExchangeRequestForm(scientificExchangeRequestFormUuid: $serfUuid) {
      ...AllSerfTopics
      healthCareProfessional {
        city
        firstName
        lastName
        postalCode
      }
    }
  }
  ${FULL_SERF}
`
