<template>
  <div class="SearchSettings">
    <v-row no-gutters class="mb-3">
      <v-expansion-panels :value="0" readonly accordion class="expansion-panels">
        <v-expansion-panel>
          <v-expansion-panel-header hide-actions>
            <v-container ma-0 pa-0>
              <v-row>
                <v-col class="d-flex flex-row-reverse">
                  <v-switch v-model="value.veevaNetwork" :label="$t('search-settings.veeva-network-label')" class="mt-0" dense hide-details color="success"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex flex-row-reverse reset-btn">
                  <v-btn v-show="displayReset" color="primary" text @click="resetSearchFilters">
                    {{ $t`reset` }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-header>
          <v-expansion-panel-content eager class="expansion-content">
            <v-row dense class="mb-3">
              <v-col cols="12" md="10">
                <SelectBuZoneTarget v-model="value" />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" md="5">
                <v-select
                  v-model="value.serfStatuses"
                  :items="status"
                  menu-props="offsetY"
                  label="Statut du SERF"
                  background-color="#f8f8f8"
                  filled
                  dense
                  hide-details
                  multiple
                  outlined
                >
                </v-select>
              </v-col>
              <v-col cols="6" md="5">
                <v-select
                  v-model="value.serfChoices"
                  :items="serfChoices"
                  menu-props="offsetY"
                  :label="$t`topics`"
                  background-color="#f8f8f8"
                  filled
                  dense
                  hide-details
                  multiple
                  outlined
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script>
import { SERF_STATUS } from '@/const/serf'
import SelectBuZoneTarget from '@/components/serf/selectBuZoneTarget'
import shared from '@/const/shared'
import _unionBy from 'lodash/unionBy'

export default {
  name: 'SearchSettings',
  components: { SelectBuZoneTarget },
  props: {
    value: Object
  },
  computed: {
    availableBusinessUnitExternalIds () {
      return this.$route.meta.availableBusinessUnitExternalIds
    },
    availableBusinessUnitUuids () {
      return this.$store.state.bus
        .filter(bu => this.availableBusinessUnitExternalIds.includes(bu.externalId))
        .map(bu => bu.businessUnitUuid)
    },
    availableSerfTypes() {
      return this.$store.state.bus
        .filter(bu => this.availableBusinessUnitUuids.includes(bu.businessUnitUuid))
        .map(bu => shared.businessUnitFormType[bu.externalId])
        .filter(Boolean)
        .filter((value, index, self) => self.indexOf(value) === index)
    },
    selectedSerfTypes() {
      if (!Array.isArray(this.value.businessUnitUuids) || this.value.businessUnitUuids.length === 0) {
        return this.availableSerfTypes
      }
      return this.$store.state.bus
        .filter(bu => this.value.businessUnitUuids.includes(bu.businessUnitUuid))
        .map(bu => shared.businessUnitFormType[bu.externalId])
        .filter(Boolean)
        .filter((value, index, self) => self.indexOf(value) === index)
    },
    serfChoices() {
      // Get group in term of serf type
      const selectedSerfGroup = this.selectedSerfTypes.map(serfType => shared.serfGroups[serfType])

      // Merge fields in groups
      const groups = {}
      for (let group of selectedSerfGroup) {
        for (let groupKey in group) {
          if (!groups[groupKey]) {
            groups[groupKey] = {...group[groupKey]}
          }
          else {
            groups[groupKey].fields = _unionBy(groups[groupKey].fields, group[groupKey].fields, 'fieldFullName')
          }
        }
      }

      // Create choices list
      let choices = []
      for (let group of Object.values(groups)) {
        const fields = group.fields.filter(field => field.fieldType === shared.fieldType.BOOLEAN)
        if (fields.length === 0) {
          continue
        }
        choices.push({ header: this.$t(group.label) })
        choices.push({ divider: true  })
        choices = choices.concat(fields.map((field) => {
          let labelSuffix = ''
          if (this.availableSerfTypes.length !== 1 && field.serfType !== shared.serfType.DEFAULT) {
            labelSuffix = ` (${field.serfType})`
          }
          return { value: field.fieldFullName, text: this.$t(field.label) + labelSuffix }
        }))
      }
      return choices
    },
    status() {
      return [
        SERF_STATUS.find(status => status.value === this.$const.serfStatus.SIGNED),
        SERF_STATUS.find(status => status.value === this.$const.serfStatus.NOT_SIGNED),
        SERF_STATUS.find(status => status.value === this.$const.serfStatus.EXPIRED),
        SERF_STATUS.find(status => status.value === this.$const.serfStatus.EXPIRES_SOON),
        SERF_STATUS.find(status => status.value === this.$const.serfStatus.REFUSED),
        SERF_STATUS.find(status => status.value === this.$const.serfStatus.REPEALED)
      ]
    },
    mslMeta() {
      return this.$get(this.$store.state, 'myUser.mslMeta', null)
    },
    userZone() {
      if (!this.mslMeta) return null

      return this.mslMeta.zone
    },
    displayReset() {
      return (
        Object.keys(this.value).filter(property => {
          if (this.resetKeepedFields.includes(property)) return false

          if (Array.isArray(this.value[property]) && this.value[property].length > 0) return true

          return false
        }).length > 0
      )
    },
    resetKeepedFields() {
      return ['lastName']
    }
  },

  methods: {
    resetSearchFilters() {
      let search = {}

      Object.keys(this.value)
        .filter(property => this.resetKeepedFields.includes(property))
        .forEach(property => {
          search[property] = this.value[property]
        })

      this.$emit('input', search)
    }
  },

  watch: {
    $route(to, from) {
      if (from.name !== to.name) {
        // this.value.businessUnitUuids = null
        this.resetSearchFilters()
      }
    }
  }
}
</script>

<style lang="scss">
.SearchSettings {
  .expansion-content .v-select__selections {
    flex-wrap: nowrap;
  }
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
}
</style>

<style scoped lang="scss">
.SearchSettings {
  .expansion-panels {
    :before {
      box-shadow: none;
    }

    .v-expansion-panel-header,
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }

  .switch {
    white-space: nowrap;
    font-size: 1.3em;
    font-weight: 500;
  }

  .reset-btn {
    height: 60px
  }
}
</style>
