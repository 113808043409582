import gql from 'graphql-tag'

export const GET_USER_STATS = gql`
  query user($userUuid: GUID!) {
    user(userUuid: $userUuid) {
      lastName
      firstName
      userUuid
      mslMeta {
        zone {
          zoneUuid
          label
          serfStats {
            lastSigned {
              firstName
              lastName
            }
            totalSignedSerf
            totalTarget
            expireSoonSerf
            expiredSerf
          }
        }
      }
    }
  }
`
