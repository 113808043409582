<template>
  <v-container fluid class="HomeSerf pa-0">
    <div
      class="background"
      :style="{ backgroundImage: `url('${require('@/assets/serf-home-background.png')}')` }"
    ></div>
    <div
      class="relative py-12 display-1 font-weight-regular text-center white--text font-weight-bold"
      v-html="$t('search-pds')"
    />
    <v-row class="max-width-page mx-auto">
      <v-col cols="12">
        <v-card class="search-card pa-12">
          <v-row class="mb-0">
            <v-col class="pb-0 pr-0">
              <div class="label-input body-2 grey--text text--darken-1">{{ $t`serf-search-name` }}</div>
              <v-text-field
                v-model="searchSettings.lastName"
                outlined
                height="56"
                append-icon="mdi-magnify"
                :placeholder="$t('search-pds-placeholder')"
                @keydown.enter="gotoList"
                hide-details
                class="name-field"
              />
            </v-col>
            <v-col class="pb-0 pl-0 pr-md-0">
              <div class="label-input body-2 grey--text text--darken-1 ellipsis">{{ $t('serf-search-location') }}</div>
              <v-text-field
                v-model="postalCodeOrCity"
                type="text"
                outlined
                height="56"
                append-icon="mdi-map-marker"
                :placeholder="$t('search-zip-or-town-placeholder')"
                @keydown.enter="gotoList"
                hide-details
                class="cp-field"
                :class="{ radiusRight: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs }"
              />
            </v-col>
            <v-col
              class="pb-0 pl-md-0 flex-grow-0"
              :class="{ 'col col-12': $vuetify.breakpoint.sm || $vuetify.breakpoint.xs }"
            >
              <div class="d-none d-md-block label-input"></div>
              <v-btn
                depressed
                :block="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
                color="primary"
                height="56"
                :disabled="disableSearch"
                @click="gotoList"
                class="search-button px-9"
                :class="{ allRadius: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs }"
              >
                {{ $t('search') }}
              </v-btn>
            </v-col>
          </v-row>

          <div class="mb-5"><SearchSettings v-model="searchSettings" /></div>

          <div><RecurringResearch v-model="searchSettings" /></div>
          <div v-if="hasRight($const.right.viewEmptySerf)" class="mt-10">
            <v-btn
              color="#644bbb"
              height="70px"
              :href="`${$baseUrl}/${$route.meta.serfUri}`"
              target="_blank"
              depressed
              dark
              large
              class="px-10 py-3"
            >
              <v-icon left>mdi-printer</v-icon>
              <span class="font-weight-bold white--text">{{ $t(`print-serf`, { name: $route.meta.appName }) }}</span>
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="max-width-page mx-auto pb-8">
      <v-col v-if="$store.state.myUser.userTypes.includes($const.userType.MSL)" cols="12" md="6"><MslStats /></v-col>
      <v-col v-if="hasRight($const.right.viewBusinessUnitStats)" cols="12" md="6"><BuStats /> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

import SearchSettings from '@/components/serf/SearchSettings'
import RecurringResearch from '@/components/serf/RecurringResearch'
import MslStats from '@/components/serf/MslStats'
import BuStats from '@/components/serf/BuStats'
export default {
  name: 'Home',
  // props: {
  //   businessUnitUuids: {
  //     type: Array,
  //     default: () => []
  //   }
  // },
  components: { BuStats, MslStats, RecurringResearch, SearchSettings },
  data() {
    return {
      postalCodeOrCity: '',
      searchSettings: {}
    }
  },

  mounted() {
    const isAdmin = this.$store.state.myUser.userTypes.find(
      type =>
        type === this.$const.userType.ADMIN ||
        type === this.$const.userType.SUPER_ADMIN ||
        type === this.$const.userType.MEGA_ADMIN
    )
    if (!isAdmin) {
      this.$router.push({ path: '/serf/error' })
    }
  },

  computed: {
    ...mapGetters(['hasRight']),
    disableSearch() {
      return !this.postalCodeOrCity && Object.keys(this.searchSettings).length <= 0
    }
  },

  methods: {
    gotoList() {
      const query = {
        ...this.searchSettings
      }

      if (this.postalCodeOrCity) {
        if (isNaN(this.postalCodeOrCity)) query.city = this.postalCodeOrCity
        else query.postalCode = this.postalCodeOrCity
      }

      this.$router.push({ path: `/${this.$route.path.split('/')[1]}/list`, query })
    }
  }
}
</script>

<style lang="scss">
.HomeSerf {
  --color-primary: #644bbb;
  position: relative;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(var(--color-primary) 549px, white 550px) no-repeat;
    background-size: cover;
    height: 630px;
  }

  .name-field {
    border-radius: 8px 0 0 8px;
    border-right: 0;
    fieldset {
      border-right-color: #d0d0d0;
    }
  }

  .cp-field {
    border-radius: 0;
    border-left: 0;
    fieldset {
      border-left: 0;
    }
    &.radiusRight {
      border-radius: 0 8px 8px 0;
    }
  }

  .search-button {
    border-radius: 0 8px 8px 0;
    color: white;
    &.allRadius {
      border-radius: 8px;
    }
  }

  .search-card {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.03), 0 6px 6px rgba(0, 0, 0, 0.04);
  }

  .max-width-page {
    max-width: 1200px;
  }
  .label-input {
    height: 23px;
    display: flex;
    align-items: end;
    justify-content: end;
  }

  .settings-title {
    font-size: 21px;
  }
}
</style>
