<template>
  <v-container class="error-page" fill-height tag="section">
    <v-row class="text-center" justify="center">
      <v-col cols="auto">
        <h1 class="title font-weight-black">404</h1>

        <div class="display-3 mb-5 mt-10">This application has been removed :(</div>

        <v-btn depressed to="/"> Go back to tooly board </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PagesError'
}
</script>

<style lang="scss">
.error-page {
  h1 {
    font-size: 12rem;
  }
}
</style>
