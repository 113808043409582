<template>
  <div class="DonutStats">
    <div v-if="loading" class="DonutStats-loaderContainer">
      <LoaderTab color="primary" />
    </div>
    <apexchart v-else width="500" height="350" type="donut" :options="chartOptions" :series="series" />
  </div>
</template>

<script>
import LoaderTab from '@/components/congrex/tabs/LoaderTab'
export default {
  name: 'DonutStats',
  components: { LoaderTab },
  props: {
    data: Array,
    loading: {
      type: Boolean,
      default: false
    }
  },

  data: function() {
    return {
      chartOptions: {
        colors: [],
        dataLabels: {
          enabled: false
        },
        fill: {
          colors: []
        },
        labels: [],
        legend: {
          show: false
        },
        noData: {
          text: 'no data'
        },
        plotOptions: {
          pie: {
            donut: {
              size: '85%',
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  label: this.$t`total-msl-responsible`
                }
              }
            },
            expandOnClick: false
          }
        },
        chart: {
          id: 'vuechart-example'
        }
      },
      series: [1]
    }
  },

  computed: {
    colors() {
      if (this.totalValues === 0) return ['hsl(0, 0%, 54%)']

      return this.data.map(v => v.color)
    },
    labels() {
      if (this.totalValues === 0) return ['no value']

      return this.data.map(v => v.label)
    },
    totalValues() {
      if (this.data.length === 0) return 0
      return this.data.map(v => v.value).reduce((a, b) => a + b)
    },
    values() {
      if (this.totalValues === 0) return []

      return this.data.map(v => v.value)
    }
  },

  watch: {
    data() {
      this.updateData()
    }
  },

  created() {
    this.updateData()
  },

  methods: {
    updateData() {
      this.chartOptions.fill.colors.splice(0, this.chartOptions.colors.length)
      this.chartOptions.fill.colors.push(...this.colors)

      this.chartOptions.labels.splice(0, this.chartOptions.labels.length)
      this.chartOptions.labels.push(...this.labels)

      this.$nextTick(() => {
        this.series = this.values
      })
    }
  }
}
</script>

<style lang="scss">
.DonutStats {
  .apexcharts-canvas {
    margin: 0 auto;
  }
}

.DonutStats-loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
}
</style>
