import gql from 'graphql-tag'

export const UPDATE_SIGNATURE_DATE = gql`
  mutation updateSignatureDate($serf: SerfUpdateInput!, $serfUuid: GUID!) {
    updateSerf(serf: $serf, serfUuid: $serfUuid) {
      createdAt
      signatureDate
      updatedAt
    }
  }
`
