import gql from 'graphql-tag'

import { VEEVA_USER_FULL } from './VeevaUser'

export const GET_HCP_FOR_SOLICITATION = gql`
  query veevaUsers(
    $businessUnitUuids: [GUID]
    $city: String
    $lastName: String
    $postalCode: String
    $mslTarget: Boolean!
    $serfChoices: [String!]
    $serfStatuses: [SerfStatusEnum!]
    $targets: [String!]
    $zoneUuids: [GUID!]
    $offset: Int = 0
    $limit: Int = 10
  ) {
    veevaUsers(
      businessUnitUuids: $businessUnitUuids
      city: $city
      lastName: $lastName
      postalCode: $postalCode
      mslTarget: $mslTarget
      serfChoices: $serfChoices
      serfStatuses: $serfStatuses
      targets: $targets
      zoneUuids: $zoneUuids
      offset: $offset
      limit: $limit
    ) {
      results {
        ...VeevaUserFull
      }
      total
    }
  }
  ${VEEVA_USER_FULL}
`

export const GET_HCPS_FOR_SOLICITATION = gql`
  query veevaUsers(
    $businessUnitUuids: [GUID]
    $city: String
    $lastName: String
    $postalCode: String
    $mslTarget: Boolean!
    $serfChoices: [String!]
    $serfStatuses: [SerfStatusEnum!]
    $targets: [String!]
    $zoneUuids: [GUID!]
    $offset: Int = 0
    $limit: Int = 10
    $sorts: [UserSort]! = []
  ) {
    veevaUsers(
      businessUnitUuids: $businessUnitUuids
      city: $city
      lastName: $lastName
      postalCode: $postalCode
      mslTarget: $mslTarget
      serfChoices: $serfChoices
      serfStatuses: $serfStatuses
      targets: $targets
      zoneUuids: $zoneUuids
      offset: $offset
      limit: $limit
      sorts: $sorts
    ) {
      results {
        cities
        email
        firstName
        healthCareProfessional {
          healthCareProfessionalUuid
          attentendIntercontinentalCongressYears
          businessUnit {
            label
          }
          firstName
          lastName
          emailAddress
          city
          solicitations {
            congressUuid
          }
          specialty
          targetAd
          targetBbu
          targetKme
          targetMs
          targetSma
          title
          zone {
            label
          }
          isSolicitationBannedFromGathering {
            name
          }
        }
        lastName
        veevaId
      }
      total
    }
  }
`

export const GET_VEEVA_USERS = gql`
  query veevaUsers(
    $businessUnitUuids: [GUID]
    $city: String
    $lastName: String
    $postalCode: String
    $mslResponsibleUuid: String
    $haveMslResponsible: Boolean
    $mslTarget: Boolean!
    $serfChoices: [String!]
    $serfStatuses: [SerfStatusEnum!]
    $targets: [String!]
    $zoneUuids: [GUID!]
    $offset: Int = 0
    $limit: Int = 10
    $sorts: [UserSort]! = []
  ) {
    veevaUsers(
      businessUnitUuids: $businessUnitUuids
      city: $city
      lastName: $lastName
      postalCode: $postalCode
      mslResponsibleUuid: $mslResponsibleUuid
      haveMslResponsible: $haveMslResponsible
      mslTarget: $mslTarget
      serfChoices: $serfChoices
      serfStatuses: $serfStatuses
      targets: $targets
      zoneUuids: $zoneUuids
      offset: $offset
      limit: $limit
      sorts: $sorts
    ) {
      results {
        ...VeevaUserFull
      }
      total
    }
  }
  ${VEEVA_USER_FULL}
`
export const GET_HCPS_FOR_HCP_HISTORICAL = gql`
  query veevaUsers(
    $businessUnitUuids: [GUID]
    $lastName: String
    $offset: Int = 0
    $limit: Int = 10
    $sorts: [UserSort]! = []
    $zoneUuids: [GUID!]
  ) {
    veevaUsers(
      businessUnitUuids: $businessUnitUuids
      lastName: $lastName
      mslTarget: true
      offset: $offset
      limit: $limit
      sorts: $sorts
      zoneUuids: $zoneUuids
    ) {
      total
      results {
        healthCareProfessional {
          attentendIntercontinentalCongressYears
          healthCareProfessionalUuid
          businessUnit {
            label
          }
          city
          emailAddress
          firstName
          isSolicitationBannedFromGathering {
            name
          }
          lastName
          specialty
          targetAd
          targetBbu
          targetKme
          targetMs
          targetSma
          title
          veevaId
          zone {
            label
          }
        }
      }
    }
  }
`
