<template>
  <v-dialog :value="value" fullscreen persistent no-click-animation hide-overlay transition="dialog-bottom-transition">
    <v-dialog :value="displayModal" persistent no-click-animation max-width="450">
      <v-card :loading="loadingRefuseModal">
        <template v-if="refuseModal">
          <v-card-title>{{ $t('sure-to-refuse-serf') }}</v-card-title>
          <v-card-text>{{ $t('sure-to-refuse-serf-content') }}</v-card-text>
          <v-divider class="mt-8" />
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" text @click="refuseModal = false" class="mx-3">{{ $t('cancel') }}</v-btn>
            <v-btn color="success" text @click="refuse" class="mx-3">{{ $t('confirm') }}</v-btn>
          </v-card-actions>
        </template>
        <template v-else>
          <v-card-title>{{ $t('sure-to-repeal-serf') }}</v-card-title>
          <v-card-text>{{ $t('sure-to-repeal-serf-content') }}</v-card-text>
          <v-divider class="mt-8" />
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" text @click="repealModal = false" class="mx-3">{{ $t('cancel') }}</v-btn>
            <v-btn color="success" text @click="repeal" class="mx-3">{{ $t('confirm') }}</v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>

    <v-card class="Serf-Carousel" tile>
      <v-container fluid :class="getColorState(item.serfStatus)">
        <div class="text-right">
          <v-btn icon dark @click="$emit('input', false)" class="mr-2">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="text-center display-1 white--text">{{ `${item.firstName} ${item.lastName}` }}</div>
        <v-container class="text-center white--text">
          <v-row justify="center">
            <v-btn
              v-if="$store.getters.hasRight($const.right.createSerf)"
              @click="formSerf = true"
              color="white"
              min-width="150"
              outlined
              class="mx-3 mt-3"
            >
              {{ item.serfStatus ? $t('create-new-version') : $t('create-serf') }}
            </v-btn>
            <v-btn
              v-if="displayRevoke"
              @click="repealModal = true"
              color="white"
              min-width="150"
              outlined
              class="mx-3 mt-3"
            >
              {{ $t('revoke') }}
            </v-btn>
            <v-btn
              v-if="$store.getters.hasRight($const.right.downloadSerf) && archivesLoaded.length > 0"
              :href="downloadUrl"
              color="white"
              min-width="150"
              outlined
              class="mx-3 mt-3"
            >
              {{ $t('download') }}
            </v-btn>
            <v-btn
              v-if="displayRefuse"
              @click="refuseModal = true"
              color="white"
              min-width="200"
              outlined
              class="mx-3 mt-3"
            >
              <v-icon left>mdi-close</v-icon>{{ $t('refuse') }}
            </v-btn>
          </v-row>
        </v-container>
      </v-container>

      <div v-if="Object.keys(hcp).length === 0" class="d-flex justify-center pt-12">
        <v-progress-circular color="primary" indeterminate :size="100" />
      </div>
      <template v-else>
        <v-container v-if="!hasRight($const.right.viewSerfArchives) || item.serfStatus === undefined">
          <v-row justify="center" class="pt-10">
            <v-chip light color="black" class="px-8 mb-12 white--text text-uppercase font-weight-medium">
              {{ $t('status-not-signed') }}
            </v-chip>
          </v-row>
          <!-- @todo - disabled while the true form as not been updated -->
          <!-- <v-row class="text-center"> <img src="@/assets/serf-template.jpg" class="serf-template" /> </v-row> -->
        </v-container>

        <v-carousel
          v-else
          v-model="carouselValue"
          height="auto"
          :continuous="false"
          hide-delimiters
          class="pt-4 pb-10"
          light
        >
          <div class="my-2 text-center">
            <div class="font-italic">{{ textDate }}</div>
            <v-btn v-if="canEditSignatureDate" @click="modalSignatureDate = true" class="text-none" outlined>{{
              $t('carousel.updateSignatureDateButton')
            }}</v-btn>
          </div>
          <v-carousel-item v-if="archivesLoaded.length === 0 && lastSerf" class="text-center">
            <Form_Serf notEditable :item="lastSerf" />
          </v-carousel-item>
          <v-carousel-item v-for="(archive, i) in hcp.archives" :key="archive.downloadUrl" class="text-center">
            <Form_Serf v-if="i === hcp.archives.length - 1" notEditable :item="lastSerf" />
            <div v-else class="py-4">
              <v-card :loading="!archivesLoaded[i]" elevation="5" light width="400px" class="mx-auto">
                <div class="grey lighten-3 px-7 pt-5">
                  <div v-if="archive.type === 'application/pdf'" style="height: 350px; overflow: hidden;">
                    <pdf :src="$baseUrl + archive.previewUrl" @loaded="archivesLoaded[i] = true" />
                  </div>
                  <v-img
                    v-else
                    :src="$baseUrl + archive.previewUrl"
                    @load="archivesLoaded[i] = true"
                    position="top center"
                    height="350px"
                  />
                  <div class="subtitle-2">
                    <v-chip v-if="archive.type === 'application/pdf'" dark label color="red" class="px-2 mr-4"
                      >PDF</v-chip
                    >
                  </div>
                </div>
              </v-card>
            </div>
          </v-carousel-item>
        </v-carousel>
      </template>
    </v-card>

    <DialogForm v-model="formSerf" :item="Object.assign({}, lastSerf, item)" />

    <v-dialog v-model="modalSignatureDate" width="600px">
      <v-card :loading="loadingModalSignatureDate">
        <v-card-title class="mb-4">{{ $t('carousel.updateSignatureDateButton') }}</v-card-title>
        <v-card-text class="px-10 mb-5">
          <v-date-picker v-model="signatureDate" full-width :max="new Date().toISOString()" />
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-3">
          <v-spacer />
          <v-btn @click="modalSignatureDate = false" color="error" depressed>cancel</v-btn>
          <v-btn @click="updateSignatureDate" color="success" depressed>ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import _get from 'lodash/get'
import moment from 'moment'
import { mapGetters } from 'vuex'

import pdf from 'vue-pdf'
import { GET_HCP } from '@/graphql/Hcp/GetHcp'
import { REFUSE_SERF } from '@/graphql/Serf/RefuseSerf'
import { REPEAL_SERF } from '@/graphql/Serf/RepealSerf'
import DialogForm from '@/components/serf/DialogForm'
import Form_Serf from '@/views/serf/Form'
import { UPDATE_SIGNATURE_DATE } from '@/graphql/Serf/UpdateSerf'

const initData = {
  formSerf: false,
  hcp: {},
  carouselValue: 0,
  loadingModalSignatureDate: false,
  loadingRefuseModal: false,
  modalSignatureDate: false,
  refuseModal: false,
  repealModal: false,
  signatureDate: null
}

export default {
  name: 'Serf_Carousel',

  components: { DialogForm, pdf, Form_Serf },

  props: {
    value: Boolean,
    item: Object,
    getColorState: Function
  },

  data() {
    return {
      formSerf: false,
      hcp: {},
      carouselValue: 0,
      loadingModalSignatureDate: false,
      loadingRefuseModal: false,
      modalSignatureDate: false,
      refuseModal: false,
      repealModal: false,
      signatureDate: null
    }
  },

  computed: {
    ...mapGetters(['hasRight']),
    archivesLoaded() {
      return this.hcp.archives ? this.hcp.archives.map(() => true) : []
    },
    canEditSignatureDate() {
      if (!this.hcp.scientificExchangeRequestForm) return null
      const createdAt = new Date(this.hcp.scientificExchangeRequestForm.createdAt)

      return createdAt <= this.$const.editableSignatureSerfPivotDate
    },
    displayModal() {
      return this.refuseModal || this.repealModal
    },
    displayRevoke() {
      const { status } = this.item
      return (
        (this.hasRight(this.$const.right.repealSerf) && status === 'signed') ||
        (status === 'expires_soon' && this.hcp.archives && this.carouselValue === this.hcp.archives.length - 1)
      )
    },
    displayRefuse() {
      const { status } = this.item
      return (this.hasRight(this.$const.right.refuseSerf) && !status) || status === 'repealed' || status === 'expired'
    },
    downloadUrl() {
      return `${this.$baseUrl}${_get(this.hcp, `archives[${this.carouselValue}].downloadUrl`)}`
    },
    textDate() {
      if (!this.hcp.archives || !this.hcp.scientificExchangeRequestForm) return null

      const archive = this.hcp.archives[this.carouselValue]
      const date =
        this.archivesLoaded &&
        (this.archivesLoaded.length === 0 || this.carouselValue === this.archivesLoaded.length - 1)
          ? this.hcp.scientificExchangeRequestForm.signatureDate
          : archive.statusDate
      const dateFormatted = moment(date).format('DD/MM/YYYY')

      return this.$t(`serf-status.${this.$get(archive, 'status', this.$const.serfStatus.SIGNED)}`, {
        date: dateFormatted
      })
    },
    lastSerf() {
      if (!this.hcp.scientificExchangeRequestForm) return null

      return {
        lastName: this.hcp.lastName,
        firstName: this.hcp.firstName,
        postalCode: this.hcp.postalCode,
        city: this.hcp.city,
        healthCareProfessionalUuid: this.item.healthCareProfessionalUuid,
        ...this.hcp.scientificExchangeRequestForm
      }
    }
  },

  apollo: {
    hcp: {
      query: GET_HCP,
      variables() {
        this.resetData()
        return {
          healthCareProfessionalUuid: this.item.healthCareProfessionalUuid
        }
      },
      update: ({ healthCareProfessional }) => healthCareProfessional,
      result({ data: { healthCareProfessional } }) {
        this.carouselValue = healthCareProfessional.archives.length > 0 ? healthCareProfessional.archives.length - 1 : 0
      },
      skip() {
        return Boolean(!this.item.expirationDate)
      }
    }
  },

  updated() {
    if (!this.value) {
      this.carouselValue = 0
    }
  },

  methods: {
    refuse() {
      this.loadingRefuseModal = true
      this.$apollo
        .mutate({
          mutation: REFUSE_SERF,

          variables: {
            healthCareProfessionalUuid: this.item.healthCareProfessionalUuid,
            veevaId: !this.item.healthCareProfessionalUuid ? this.item.veevaId : ''
          }
        })
        .then(() => this.$router.push(`/${this.$route.path.split('/')[1]}`))
    },
    repeal() {
      this.repealModal = false
      this.$apollo
        .mutate({
          mutation: REPEAL_SERF,

          variables: {
            healthCareProfessionalUuid: this.item.healthCareProfessionalUuid
          }
        })
        .then(() => this.$router.push(`/${this.$route.path.split('/')[1]}`))
    },
    resetData() {
      Object.keys(initData).forEach(key => (this[key] = initData[key]))
    },
    async updateSignatureDate() {
      this.loadingModalSignatureDate = true

      const { data } = await this.$apollo.mutate({
        mutation: UPDATE_SIGNATURE_DATE,
        variables: {
          serf: { signatureDate: this.signatureDate },
          serfUuid: this.hcp.scientificExchangeRequestForm.scientificExchangeRequestFormUuid
        }
      })

      this.hcp.scientificExchangeRequestForm = { ...this.hcp.scientificExchangeRequestForm, ...data.updateSerf }
      this.modalSignatureDate = false
      this.loadingModalSignatureDate = false
    }
  }
}
</script>

<style lang="scss">
.Serf-Carousel {
  .v-window__prev,
  .v-window__next {
    position: fixed;
  }
}
</style>

<style scoped lang="scss">
.Serf-Carousel {
  .serf-template {
    width: 800px;
    max-width: 75%;
    position: fixed;
    transform: translateX(-50%);
    left: 50%;
    opacity: 0.3;
    box-shadow: 0px 3px 9px 1px rgba(0, 0, 0, 0.2);
  }
  .ellipsis {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
