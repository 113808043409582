import gql from 'graphql-tag'

export const REPEAL_SERF = gql`
  mutation repealSerf($healthCareProfessionalUuid: GUID!) {
    repealSerf(healthCareProfessionalUuid: $healthCareProfessionalUuid) {
      firstName
      lastName
    }
  }
`
