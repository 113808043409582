<template>
  <v-card v-if="zones" outlined elevation="1" class="BuStats">
    <v-row justify="center" class="mb-3">
      <v-col>
        <v-row align="center" justify="center" class="mt-3 text-center">
          <v-col cols="2">
            <v-icon v-if="availableBusinessUnitExternalIds.length > 1" large @click="moveBuIndex('prev')">mdi-chevron-left</v-icon>
          </v-col>
          <v-col class="display-2 primary--text">{{ currentBu.label }}</v-col>
          <v-col cols="2">
            <v-icon v-if="availableBusinessUnitExternalIds.length > 1" large @click="moveBuIndex('next')">mdi-chevron-right</v-icon>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div>
      <v-row justify="center" align="center" class="text-center">
        <v-col class="px-0">
          <span class="grey--text">{{ $t`based-on-msl-responsible` }}</span>
        </v-col>
      </v-row>
      <DonutStats :data="donutChartDatas" :loading="loading.serfStats" />
    </div>
    <v-tabs v-model="tab" grow>
      <v-tab key="tab-zone">Par zone</v-tab>
      <v-tab key="tab-topic">Par sujet</v-tab>
    </v-tabs>
    <v-tabs-items :class="{ blur: loading.serfStats }" v-model="tab">
      <v-tab-item key="tab-zone">
        <v-container fluid class="px-0 grey--text text--darken-2 title font-weight-regular">
          <v-list-item-group>
            <v-list-item inactive>
              <v-row align="center" class="px-3">
                <v-col cols="4" class="text-uppercase">{{ $t`total` }}</v-col>
                <v-col cols="6">
                  <v-progress-linear :value="ratioBuSigned" color="success" height="12" class="my-2" />
                </v-col>
                <v-col cols="2" class="subtitle-1">{{ totalBuSigned }}/{{ totalBuTarget }}</v-col>
              </v-row>
            </v-list-item>
            <div v-for="(zone, i) in zones" :key="i">
              <v-divider />
              <v-list-item @click="goTo({ mslTarget: true, mslResponsibleUuid: zone.mslUserUuid, serfStatuses: [ $const.serfStatus.SIGNED, $const.serfStatus.EXPIRES_SOON ] })">
                <v-row align="center" class="px-3">
                  <v-col cols="4" class="ellipsis py-1">
                    <div class="title text-capitalize ellipsis">{{ zone.mslFirstname }} {{ zone.mslLastname }}</div>
                    <div class="subtitle-1 text-lowercase ellipsis">{{ zone.label }}</div>
                  </v-col>
                  <v-col cols="6"> <v-progress-linear :value="ratioSigned[i]" height="12" class="my-2" /> </v-col>
                  <v-col cols="2" class="subtitle-1">{{ zone.totalSignedSerf }}/{{ zone.totalTarget }}</v-col>
                </v-row>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-container>
      </v-tab-item>
      <v-tab-item key="tab-topic">
        <v-container fluid class="pa-0 grey--text text--darken-2 title font-weight-regular">
          <v-list-item-group>
            <v-list-item inactive class="grey lighten-3">
              <v-row no-gutters class="px-3 py-2 mx-0 grey--text text--darken-1">
                <v-col cols="9">{{ $t`topics` }}</v-col>
                <v-col>{{ $t`signed-serf` }}</v-col>
              </v-row>
            </v-list-item>
            <div v-for="topic in topicsStats" :key="topic.label">
              <v-divider />
              <v-list-item @click="goTo({ mslTarget: true, haveMslResponsible: true, serfChoices: topic.textValue, serfStatuses: [ $const.serfStatus.SIGNED, $const.serfStatus.EXPIRES_SOON ] })">
                <v-row class="px-3">
                  <v-col cols="9" class="ellipsis width-0">{{ topic.label }}</v-col>
                  <v-col class="success--text">{{ topic.value }}</v-col>
                </v-row>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
  <v-card v-else height="200" width="100%" outlined elevation="1">
    <v-overlay :value="true" opacity="0.03" absolute>
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>
  </v-card>
</template>

<script>
import _get from 'lodash/get'
import { mapState } from 'vuex'

import DonutStats from '@/components/serf/DonutStats'
import { businessUnitFormType, serfFieldSpecifications, fieldType } from '@/const/shared'
import { GET_BU_SERF_STATS } from '@/graphql/BusinessUnit/GetBus'
import { GET_ALL_ZONES_FROM_BU } from '@/graphql/Zone/GetZones'
import _sortBy from 'lodash/sortBy'
import _upperFirst from 'lodash/upperFirst'

export default {
  name: 'BuStats',

  components: { DonutStats },

  data() {
    return {
      buIndex: 0,
      loading: {
        serfStats: false
      },
      tab: 0
    }
  },

  apollo: {
    zones: {
      query: GET_ALL_ZONES_FROM_BU,
      variables() {
        return { businessUnitUuid: this.currentBuUuid }
      },
      update: data => {
        return data.businessUnit.zones.map(item => ({
          label: _get(item, 'label'),
          mslFirstname: _get(item, 'mslMeta.user.firstName'),
          mslLastname: _get(item, 'mslMeta.user.lastName'),
          mslUserUuid: _get(item, 'mslMeta.user.userUuid'),
          totalSignedSerf: _get(item, 'serfStats.totalSignedSerf'),
          totalTarget: _get(item, 'serfStats.totalTarget'),
          zoneUuid: _get(item, 'zoneUuid')
        }))
      },
      skip() {
        return Boolean(!this.currentBuUuid)
      }
    },
    serfStats: {
      query: GET_BU_SERF_STATS,
      variables() {
        this.loading.serfStats = true
        return { businessUnitUuid: this.currentBuUuid }
      },
      update: data => data.businessUnit.serfStats,
      result() {
        this.loading.serfStats = false
      },
      skip() {
        return Boolean(!this.currentBuUuid)
      }
    }
  },

  watch: {
    $route(to, from) {
      if (from.name !== to.name) {
        this.loading.serfStats = true
        this.tab = 0
        this.buIndex = 0
        this.$apollo.queries.zones.refetch()
        this.$apollo.queries.serfStats.refetch()
      }
    }
  },

  computed: {
    ...mapState(['bus', 'applications', 'myUser']),
    availableBusinessUnitExternalIds () {
      return this.$route.meta.availableBusinessUnitExternalIds
    },
    availableBusinessUnitUuids () {
      return this.$store.state.bus
        .filter(bu => this.availableBusinessUnitExternalIds.includes(bu.externalId))
        .map(bu => bu.businessUnitUuid)
    },
    currentBuUuid () {
      if (!this.applications) {
        return null
      }
      return this.availableBusinessUnitUuids[this.buIndex]
    },
    currentBu () {
      if (!this.currentBuUuid) {
        return {}
      }
      return this.bus.find(bu => bu.businessUnitUuid === this.currentBuUuid)
    },
    donutChartDatas() {
      if (this.tab === 0) {
        if (!this.zones) return []

        const filtered = this.zones.filter(zone => zone.totalSignedSerf > 0)
        const colors = this.arrayColors(35, 80, filtered.length)
        const signed = filtered.map((zone, i) => ({
          label: `${_upperFirst(zone.mslLastname)} ${_upperFirst(zone.mslFirstname)} - ${zone.label}`,
          value: zone.totalSignedSerf,
          color: colors[i]
        }))
        // TODO -- afficher restant?
        // signed.push({ label: 'restant à signer', value: this.totalBuTarget, color: 'hsl(0, 0, 70%)' })

        return signed
      }

      if (!this.serfStats) return []
      // const stats = Object.entries(this.serfStats).filter(([key]) => key !== '__typename' && key !== 'totalTarget')
      const colors = this.arrayColors(25, 80, this.topicsStats.length)

      return this.topicsStats.map((el, i) => ({ label: el.label, value: el.value, color: colors[i] }))
    },
    ratioSigned() {
      return this.zones.map(zone => {
        if (!zone.totalTarget) return 0
        return Math.round((zone.totalSignedSerf / zone.totalTarget) * 100)
      })
    },
    totalBuSigned() {
      return this.zones.reduce((a, b) => a + b.totalSignedSerf, 0)
    },
    totalBuTarget() {
      return this.zones.reduce((a, b) => a + b.totalTarget, 0)
    },
    ratioBuSigned() {
      if (!this.totalBuTarget) return 0
      return Math.round((this.totalBuSigned / this.totalBuTarget) * 100)
    },
    topicsStats() {
      if (!this.serfStats) return []

      const serfType = businessUnitFormType[this.currentBu.externalId]
      const formSpes = serfFieldSpecifications
        .filter(spec => spec.serfType === serfType && spec.fieldType === fieldType.BOOLEAN)

      return _sortBy(
        formSpes.map(spe => {
          if (!isNaN(this.serfStats[spe.fieldFullName])) {
            return { label: this.$t(spe.label), textValue: spe.fieldFullName, value: this.serfStats[spe.fieldFullName] }
          }
          return null
        }),
        ['label']
      )
    }
  },

  methods: {
    arrayColors(min, max, length, hue = 206, saturation = 93) {
      if (length === 1) return [`hsl(${hue}, ${saturation}%, ${(max - min) / 2 + min}%)`]

      const step = Math.floor((max - min) / length - 1)
      return new Array(length).fill('').map((v, i) => `hsl(${hue}, ${saturation}%, ${max - i * step}%)`)
    },
    goTo(query, mslTarget = true) {
      const bu = { businessUnitUuids: this.currentBuUuid }
      this.$router.push({ path: `/${this.$route.path.split('/')[1]}/list`, query: { ...query, ...bu, mslTarget } })
    },
    moveBuIndex(direction) {
      if (direction === 'next') {
        if (this.buIndex === this.availableBusinessUnitExternalIds.length - 1) {
          this.buIndex = 0
        } else {
          this.buIndex++
        }
      } else {
        if (this.buIndex === 0) {
          this.buIndex = this.availableBusinessUnitExternalIds.length - 1
        } else {
          this.buIndex--
        }
      }
    }
  }
}
</script>

<style lang="scss">
.BuStats {
  .width-0 {
    width: 0;
  }
}
.blur {
  filter: blur(3px);
}
</style>
