<template>
  <v-dialog v-model="value" persistent no-click-animation max-width="400">
    <v-card class="Modal-Generate-Link">
      <v-card-title class="headline mb-5">
        Envoyer un mail de relance à {{ item.lastName }} {{ item.firstName }}
      </v-card-title>
      <v-card-text>
        <div class="black--text mb-1 subtitle-1">Envoyer à</div>
        <v-text-field
          :value="item.email || 'email non renseigné'"
          :disabled="!item.email"
          class="input-field"
          readonly
          outlined
          height="56"
        >
          <template v-slot:append class="red">
            <v-tooltip v-model="tooltipEmail" right :close-delay="500" color="black">
              <template v-slot:activator="{ on }">
                <v-btn @click="copyClipboard(item.email)" icon v-on="on" class="paste-button">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>{{ $t`link-copied-clipboard` }}</span>
            </v-tooltip>
          </template>
        </v-text-field>

        <div class="black--text mb-1 subtitle-1">Lien du SERF</div>
        <v-text-field :loading="!mailToken" :value="mailToken" class="input-field" readonly outlined height="56">
          <template v-slot:append class="red">
            <v-tooltip v-model="tooltipLink" right :close-delay="500" color="black">
              <template v-slot:activator="{ on }">
                <v-btn @click="copyClipboard(mailToken)" icon v-on="on" class="paste-button">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>{{ $t`link-copied-clipboard` }}</span>
            </v-tooltip>
          </template>
        </v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="$emit('input', false)">{{ $t('cancel') }}</v-btn>
        <v-btn text color="success" class="mr-4" :disabled="!mailTo" :href="mailTo">{{ $t('confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { GENERATE_MAIL_TOKEN } from '@/graphql/MailToken/GenerateMailToken'

export default {
  name: 'ModalGenerateLink',

  props: {
    value: Boolean,
    item: Object
  },

  async created() {
    // Get token and hcp
    // Caution:
    //   * Use `healthCareProfessionalUuid` from `hcp` value in result of this mutation.
    //   * Don't use `healthCareProfessionalUuid` from `this.item`, because this value can be empty.
    // Note: this mutation can create hcp if this one does not already exists in database
    const {
      data: {
        getMailToken: {
          token: mailToken,
          healthCareProfessional: hcp
        }
      }
    } = await this.$apollo.mutate({
      mutation: GENERATE_MAIL_TOKEN,
      variables: { veevaId: this.item.veevaId }
    })

    const resolved = this.$router.resolve({
      name: 'Serf_for_hcp',
      params: { hcpUuid: hcp.healthCareProfessionalUuid },
      query: {
        token: mailToken
      }
    }).href
    this.mailToken = `${this.$baseUrl}/${resolved}`
  },

  data() {
    return {
      mailToken: '',
      tooltipEmail: false,
      tooltipLink: false
    }
  },

  computed: {
    mailTo() {
      if (!this.mailToken) return null

      return `mailto:${this.item.email || ''}?body=${this.mailToken}`
    }
  },

  methods: {
    copyClipboard(text) {
      navigator.clipboard.writeText(text)
    }
  }
}
</script>

<style scoped lang="scss">
.Modal-Generate-Link {
  .input-field {
    input {
      color: red;
    }

    .paste-button {
      margin-top: -5px;
    }
  }
}
</style>
