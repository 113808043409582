import i18n from '@/i18n'

export const SERF_STATUS = [
  { value: 'expired', text: i18n.t('status-expired'), class: 'serf-expired', color: '#f44336' },
  { value: 'expires_soon', text: i18n.t('status-expires-soon'), class: 'serf-soon-expire', color: '#ff9800' },
  { value: 'not_signed', text: i18n.t('status-not-signed'), class: 'serf-not-signed', color: '#979797' },
  { value: 'refused', text: i18n.t('status-refused'), class: 'serf-refused', color: '#9c27b0' },
  { value: 'repealed', text: i18n.t('status-repealed'), class: 'serf-repealed', color: '#275bb0' },
  { value: 'signed', text: i18n.t('status-signed'), class: 'serf-signed', color: '#4caf50' }
]

export const SERF_SPECIALITIES = [
  { value: 'scleroseEnPlaques', text: i18n.t('serfSpecialties.scleroseEnPlaques') },
  { value: 'amyotrophieSpinale', text: i18n.t('serfSpecialties.amyotrophieSpinale') },
  { value: 'alzheimer', text: i18n.t('serfSpecialties.alzheimer') },
  { value: 'therapeutiquesBiogen', text: i18n.t('serfSpecialties.therapeutiquesBiogen') },
  { value: 'strategieEtGuidelines', text: i18n.t('serfSpecialties.strategieEtGuideline') },
  { value: 'etudesReellesFr', text: i18n.t('serfSpecialties.resultatsEtudesFr') },
  { value: 'etudesReellesInter', text: i18n.t('serfSpecialties.resultatsEtudesInter') },
  { value: 'imagerie', text: i18n.t('serfSpecialties.imagerie') },
  { value: 'pipelineEtResultatClinique', text: i18n.t('serfSpecialties.pipelineEtResultatClinique') },
  { value: 'maladieDeCharcot', text: i18n.t('serfSpecialties.maladieDeCharcot') },
  { value: 'paralysieSupranucleaireProgressive', text: i18n.t('serfSpecialties.paralysieSupranucleaireProgressive') },
  { value: 'biomarqueur', text: i18n.t('serfSpecialties.biomarqueur') },
  { value: 'cognition', text: i18n.t('serfSpecialties.cognition') },
  { value: 'diagnostic', text: i18n.t('serfSpecialties.diagnostic') },
  { value: 'caracteristiquesBiosimilaireBbu', text: i18n.t('serfSpecialties.caracteristiquesBiosimilaireBbu') },
  { value: 'effetNoceboBbu', text: i18n.t('serfSpecialties.effetNoceboBbu') },
  { value: 'etudesReellesBbu', text: i18n.t('serfSpecialties.etudesReellesBbu') },
  { value: 'inflammatoireIntestinBbu', text: i18n.t('serfSpecialties.inflammatoireIntestinBbu') },
  { value: 'parcoursDeSoinsBbu', text: i18n.t('serfSpecialties.parcoursDeSoinsBbu') },
  { value: 'pathologiesOphtalmiquesBbu', text: i18n.t('serfSpecialties.pathologiesOphtalmiquesBbu') },
  { value: 'patientReportedOutcomesBbu', text: i18n.t('serfSpecialties.patientReportedOutcomesBbu') },
  { value: 'pipelineEtResultatCliniqueBbu', text: i18n.t('serfSpecialties.pipelineEtResultatCliniqueBbu') },
  { value: 'psoriasisEtHidrosadeniteBbu', text: i18n.t('serfSpecialties.psoriasisEtHidrosadeniteBbu') },
  { value: 'rhumatismeBbu', text: i18n.t('serfSpecialties.rhumatismeBbu') },
  { value: 'strategieEtGuidelinesBbu', text: i18n.t('serfSpecialties.strategieEtGuidelinesBbu') },
  {
    value: 'therapeuticDrugMonitoringEtOptiomisationBbu',
    text: i18n.t('serfSpecialties.therapeuticDrugMonitoringEtOptiomisationBbu')
  },
  { value: 'therapeutiquesBiogenBbu', text: i18n.t('serfSpecialties.therapeutiquesBiogenBbu') }
]

export const TARGETS = [
  { value: 'SMA_A', text: 'SMA - A' },
  { value: 'SMA_B', text: 'SMA - B' },
  { value: 'SMA_D', text: 'SMA - D' },
  { value: 'SMA_NO', text: 'SMA - NO' },
  { value: 'MS_H', text: 'MS - H' },
  { value: 'MS_M', text: 'MS - M' },
  { value: 'MS_L', text: 'MS - L' },
  { value: 'MS_NO', text: 'MS - NO' },
  { value: 'BBU_A', text: 'BBU - A' },
  { value: 'BBU_B', text: 'BBU - B' },
  { value: 'BBU_C', text: 'BBU - C' },
  { value: 'BBU_PHAR', text: 'BBU - PHAR' },
  { value: 'BBU_RES_GA', text: 'BBU - RES-GA' },
  { value: 'BBU_RES_MI', text: 'BBU - RES-MI' },
  { value: 'BBU_RES_PHAR', text: 'BBU - RES-PHAR' },
  { value: 'BBU_RES_RH', text: 'BBU - RES-RH' },
  { value: 'BBU_NO', text: 'BBU - NO' }
]
