<template>
  <div class="RecurringResearch">
    <v-row>
      <v-col>
        <span class="primary--text settings-title">{{ $t`predefined-searches` }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-chip v-if="myUser.userTypes.includes($const.userType.MSL)" outlined class="grey--text text--darken-2 px-4 mx-1 mb-2" @click="goto('ms-responsible')">
        {{ $t`msl-responsible` }}<v-icon color="primary" right>mdi-magnify</v-icon>
      </v-chip>
      <v-chip v-if="myUser.mslMeta" outlined class="grey--text text--darken-2 px-4 mx-1 mb-2" @click="goto('my-zone')">
        {{ $t`my-zone` }}<v-icon color="primary" right>mdi-magnify</v-icon>
      </v-chip>
      <v-chip outlined class="grey--text text--darken-2 mx-1 mb-2" @click="goto('serf-periming-soon')">
        {{ $t`serf-out-of-date-in-2-month` }}<v-icon color="primary" right>mdi-magnify</v-icon>
      </v-chip>
      <v-chip outlined class="grey--text text--darken-2 mx-1 mb-2" @click="goto('hcp-without-serf')">
        {{ $t`hcp-without-serf` }}<v-icon color="primary" right>mdi-magnify</v-icon>
      </v-chip>
      <v-chip outlined class="grey--text text--darken-2 mx-1 mb-2" @click="goto('outdated-serf')">
        {{ $t`serf-out-of-date` }}<v-icon color="primary" right>mdi-magnify</v-icon>
      </v-chip>
      <v-chip outlined class="grey--text text--darken-2 mx-1 mb-2" @click="goto('serf-valid')">
        {{ $t`serf-valid` }}<v-icon color="primary" right>mdi-magnify</v-icon>
      </v-chip>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'RecurringResearch',

  props: {
    value: Object
  },

  computed: {
    ...mapState(['myUser']),
    hcpListPath () {
      return `/${this.$route.path.split('/')[1]}/list`
    }
  },

  methods: {
    goto(value) {
      switch (value) {
        case 'hcp-without-serf':
          this.$router.push({ path: this.hcpListPath, query: { mslTarget: 'true', serfStatuses: 'not_signed' } })
          break
        case 'ms-responsible':
          this.$router.push({ path: this.hcpListPath, query: { mslResponsibleUuid: this.myUser.userUuid } })
          break
        case 'my-zone':
          this.$router.push({
            path: `/${this.$route.path.split('/')[1]}/list`,
            query: {
              mslTarget: 'true',
              businessUnitUuids: this.myUser.mslMeta.zone.businessUnit.businessUnitUuid,
              zoneUuids: this.myUser.mslMeta.zone.zoneUuid
            }
          })
          break
        case 'outdated-serf':
          this.$router.push({ path: this.hcpListPath, query: { mslTarget: 'true', serfStatuses: 'expired' } })
          break
        case 'serf-periming-soon':
          this.$router.push({ path: this.hcpListPath, query: { mslTarget: 'true', serfStatuses: 'expires_soon' } })
          break
        case 'serf-valid':
          this.$router.push({ path: this.hcpListPath, query: { mslTarget: 'true', serfStatuses: ['signed', 'expires_soon'] } })
          break
        default:
          console.error('this value is not valid in the switch --->', value)
      }
    }
  }
}
</script>

<style scoped></style>
