import gql from 'graphql-tag'

export const REFUSE_SERF = gql`
  mutation RefuseSerf($healthCareProfessionalUuid: GUID, $veevaId: String) {
    refuseSerf(healthCareProfessionalUuid: $healthCareProfessionalUuid, veevaId: $veevaId) {
      firstName
      lastName
      hasRefused
    }
  }
`
