<template>
  <div class="Serf">
    <LoaderTab v-if="!item" />
    <FormSerf v-else notEditable viewTeam :item="item" />
  </div>
</template>

<script>
import FormSerf from '@/views/serf/Form'
import LoaderTab from '@/components/congrex/tabs/LoaderTab'
import { GET_SERF } from '@/graphql/Serf/GetSerf'

export default {
  name: 'Serf',

  components: { LoaderTab, FormSerf },

  computed: {
    item() {
      if (!this.serf) return null

      const { healthCareProfessional, ...serf } = this.serf
      return { ...healthCareProfessional, ...serf }
    }
  },

  apollo: {
    serf: {
      query: GET_SERF,
      update: ({ scientificExchangeRequestForm }) => scientificExchangeRequestForm,
      variables() {
        return { serfUuid: this.$route.params.uuid }
      }
    }
  }
}
</script>
