import gql from 'graphql-tag'

export const GENERATE_MAIL_TOKEN = gql`
  mutation getMailToken($veevaId: String!) {
    getMailToken(veevaId: $veevaId) {
      token
      healthCareProfessional {
        healthCareProfessionalUuid
      }
    }
  }
`
